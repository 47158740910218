import React, { Suspense, lazy } from 'react';

import { useNode } from '@craftjs/core';
const FormBuilder = lazy(
  () => import('@/pages/PageBuilder/components/form-builder/FormBuilder')
);
const Footer: React.FC = (props) => {
  const {
    connectors: { connect },
    id,
  } = useNode();
  return (
    <div
      id={id}
      style={{
        backgroundImage: `url('/bg-2.png')`,
        backgroundSize: 'cover',
      }}
      className="min-h-[50vh] w-full pt-20 pb-20 md:pb-0 bg-transparent flex relative"
    >
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between gap-6">
        <div className="md:w-1/2 w-full flex justify-center items-center relative z-10">
          {' '}
          <div className="w-[90%] p-6 rounded-lg">
            <Suspense fallback={<div>Loading...</div>}>
              <FormBuilder buttonText={'Register'} {...props} />
            </Suspense>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-center text-center md:justify-between px-4 md:w-1/2">
          <div className="flex flex-col items-center justify-end w-full">
            <h2 className="text-[28px] md:text-[36px] font-bold text-[#0F172A] mb-2">
              Ready to Explore?
            </h2>
            <p className="text-[14px] md:text-[18px] text-[#0F172A] mb-4 w-full md:w-2/3 leading-relaxed text-balance">
              We're dedicated to helping your entire team stay ahead of the game
              as your business expands.
            </p>
          </div>

          <div className="flex justify-between items-end gap-4 mt-0  xl:mt-[80px]">
            <div className="flex items-center space-x-4">
              <a
                href="https://gtgsoft.com/"
                className="p-2 bg-gray-200 rounded-full text-[#0F172A] hover:bg-gray-300"
              >
                <img
                  src="/images/icons/facebook.svg"
                  alt="Facebook"
                  className="w-4 h-4"
                />
              </a>
              <a
                href="https://gtgsoft.com/"
                className="p-2 bg-gray-200 rounded-full text-[#0F172A] hover:bg-gray-300"
              >
                <img
                  src="/images/icons/linkedin.svg"
                  alt="Facebook"
                  className="w-4 h-4"
                />
              </a>
            </div>

            <div className="flex flex-col items-end">
              <div className="text-2xl font-bold text-[#005CE6]">
                <span className="font-extrabold">GTG</span>{' '}
                <span className="bg-[#005CE6] text-white px-2 py-1 rounded">
                  CRM
                </span>
              </div>
              <p className="text-[12px] mt-2 text-gray-500">
                Copyright© 2024. GTG CRM
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
