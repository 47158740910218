import React from 'react';
import { Form, message, Button } from 'antd';
import { Element } from '@craftjs/core';
import { FormBox } from '../..';
import FormInput from './FormInput';
import { GTGSButton } from '@/components/GTGS';
import { setCookie } from '@/utils/cookieUtils';

interface FormBuilderProps {
  buttonText: string;
  themeColor?: string;
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  buttonText,
  themeColor,
}) => {
  const [form] = Form.useForm();
  message.config({
    duration: 3,
    top: 80,
  });

  const handleArrayItems = (values: any) => {
    const payload = { ...values };
    const formArrayItems = document.querySelectorAll('[data-array="true"]');

    formArrayItems.forEach((item) => {
      const key = item.getAttribute('id');
      if (!key) {
        const input = item.querySelector('input');
        if (!input) return;
        const inputKey = input.getAttribute('id');
        if (!inputKey) return;
        payload[inputKey] = values[inputKey]
          ? Array.isArray(values[inputKey])
            ? values[inputKey]
            : [values[inputKey]]
          : [];
        return;
      }

      payload[key] = values[key]
        ? Array.isArray(values[key])
          ? values[key]
          : [values[key]]
        : [];
    });

    return payload;
  };

  const onFinish = async (values: any) => {
    const payload = {
      properties: handleArrayItems(values),
      content: '',
    };

    try {
      const response = await fetch('https://api.gtgcrm.com/v1/crm/cta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data?.status === 'failure') {
        message.error('Something went wrong, please try again later');
        return;
      }
      setCookie(
        'liveChatCookie',
        JSON.stringify({
          contactId: data?.contactId || '',
          workspaceId: data?.workspaceId || '',
        })
      );
      form.resetFields();
      message.success('Thank you for your registration');
    } catch (error) {
      const errorMessage = (error as Error).message;
      message.error(`Form submission failed: ${errorMessage}`);
    }
  };

  // TODO: check required properties from json shchema
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Element canvas id="form-builder-2" is={FormBox}>
        <FormInput label={'First Name'} name="first_name" isRequired={true} />
        <FormInput label={'Last Name'} name="last_name" isRequired={true} />
        <FormInput
          label={'Email'}
          name="email"
          isRequired={true}
          data-array="true"
        />
        <FormInput
          label={'Phone Numbers'}
          name="phone_numbers"
          data-array="true"
        />
      </Element>
      <button
        // type="primary"
        type="submit"
        style={themeColor ? { background: themeColor } : undefined}
        className="w-full xl:h-[48px] bg-[#005CE6] text-white font-semibold rounded-lg text-xs px-5 py-2.5 text-center"
      >
        {buttonText}
      </button>
    </Form>
  );
};

export default FormBuilder;
