import '@/styles/app.styles.scss';
// import '@/styles/app.styles.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import LandingPage from './pages/PageBuilder/LandingPage';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = () => {
  const queryClient = new QueryClient()


  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <LandingPage />
      </QueryClientProvider>
    </React.StrictMode>
  );
};

renderApp();
reportWebVitals();