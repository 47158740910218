import { GTGSIcon } from '@/components/GTGS';
import { routesPath } from '@/router/constants';
import { MenuProps } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from '../styles.module.scss';
import useCheckWorkspaceOwner from '@/hooks/useCheckWorkspaceOwner';
// ===========================================================================
// MAIN
// ===========================================================================

type MenuItem = Required<MenuProps>['items'][number];

export const useMenuData = () => {
  const { t } = useTranslation();
  const { isOwner } = useCheckWorkspaceOwner();
  const _menuConstant = useMemo(() => {
    return {
      BOOKMARK: {
        title: t('common.menu.bookmark'),
        url: routesPath.BOOKMARK,
        code: routesPath.BOOKMARK,
        icon: <GTGSIcon name="bookmarks" size={20} fill="currentColor" />,
      },
      CONTENT_BUILDER: {
        title: t('common.menu.contentBuilder'),
        url: routesPath.CONTENT_BUILDER,
        code: routesPath.CONTENT_BUILDER,
        icon: <GTGSIcon name="bookmarks" size={20} fill="currentColor" />,
      },
      EMAIL: {
        title: t('common.menu.email'),
        url: routesPath.EMAIL,
        code: routesPath.EMAIL,
      },
      DASHBOARD: {
        title: t('common.menu.dashboard'),
        url: routesPath.DASHBOARD,
        code: routesPath.DASHBOARD,
        icon: <GTGSIcon name="grid" size={20} fill="currentColor" />,
      },
      // WORKSPACE: {
      //   title: t('common.menu.workspace'),
      //   url: routesPath.WORKSPACE,
      //   code: routesPath.WORKSPACE,
      //   icon: <GTGSIcon name="bookmarks" size={20} fill='currentColor'/>
      // },
      CRM: {
        title: t('common.menu.crm'),
        url: routesPath.CRM,
        code: routesPath.CRM,
        icon: <GTGSIcon name="journal" size={20} fill="currentColor" />,
      },
      MESSAGING: {
        title: t('common.menu.messaging'),
        url: routesPath.MESSAGING,
        code: routesPath.MESSAGING,
      },
      MESSAGING_ROOT: {
        title: t('common.menu.messaging'),
        url: routesPath.MESSAGING_ROOT,
        code: routesPath.MESSAGING_ROOT,
        icon: <GTGSIcon name="bookmarks" size={20} fill="currentColor" />,
      },
      MARKETING: {
        title: t('common.menu.marketing'),
        url: routesPath.MARKETING,
        code: routesPath.MARKETING,
        icon: <GTGSIcon name="megaphone" size={20} fill="currentColor" />,
      },
      EMAIL_BUILDER: {
        title: t('common.menu.emailBuilder'),
        url: routesPath.EMAIL_BUILDER,
        code: routesPath.EMAIL_BUILDER,
        icon: <></>,
      },
      CAMPAIGNS: {
        title: t('common.menu.campaigns'),
        url: routesPath.CAMPAIGNS,
        code: routesPath.CAMPAIGNS,
      },
      EMAIL_MARKETING: {
        title: t('common.menu.email'),
        url: routesPath.EMAIL_MARKETING,
        code: routesPath.EMAIL_MARKETING,
      },
      SOCIAL: {
        title: t('common.menu.social'),
        url: routesPath.SOCIAL,
        code: routesPath.SOCIAL,
      },
      ADS: {
        title: t('common.menu.ads'),
        url: routesPath.ADS,
        code: routesPath.ADS,
      },
      FORMS: {
        title: t('common.menu.forms'),
        url: routesPath.FORMS,
        code: routesPath.FORMS,
      },
      CONTENT: {
        title: t('common.menu.content'),
        url: routesPath.CONTENT,
        code: routesPath.CONTENT,
        icon: <GTGSIcon name="file" size={20} fill="currentColor" />,
      },
      AUTOMATIONS: {
        title: t('common.menu.automations'),
        url: routesPath.AUTOMATIONS,
        code: routesPath.AUTOMATIONS,
        icon: <GTGSIcon name="hierarchy" size={20} fill="currentColor" />,
      },
      COMMERCE: {
        title: t('common.menu.commerce'),
        url: routesPath.COMMERCE,
        code: routesPath.COMMERCE,
        icon: <GTGSIcon name="wallet-menu" size={20} fill="currentColor" />,
      },
      REPORTING: {
        title: t('common.menu.reporting'),
        url: routesPath.REPORTING,
        code: routesPath.REPORTING,
        icon: <GTGSIcon name="bar-chart" size={20} fill="currentColor" />,
      },
      WEBSITE_PAGE: {
        title: t('common.menu.websitePage'),
        url: routesPath.WEBSITE_PAGE,
        code: routesPath.WEBSITE_PAGE,
        icon: <GTGSIcon name="bookmarks" size={20} fill="currentColor" />,
      },
      LANDING_PAGES: {
        title: t('common.menu.landingPage'),
        url: routesPath.LANDING_PAGES,
        code: routesPath.LANDING_PAGES,
        icon: <GTGSIcon name="bookmarks" size={20} fill="currentColor" />,
      },
      BLOG: {
        title: t('common.menu.blog'),
        url: routesPath.BLOG,
        code: routesPath.BLOG,
        icon: <GTGSIcon name="bookmarks" size={20} fill="currentColor" />,
      },
      LIBRARY: {
        title: t('common.menu.library'),
        url: routesPath.LIBRARY,
        code: routesPath.LIBRARY,
        icon: <GTGSIcon name="folder2-open" size={20} fill="currentColor" />,
      },
      CHANNELS_INTEGRATION: {
        title: t('common.menu.channels'),
        url: routesPath.CHANNELS_INTEGRATION,
        code: routesPath.CHANNELS_INTEGRATION,
      },
      STAFF: {
        title: t('common.menu.staff'),
        url: routesPath.STAFF,
        code: routesPath.STAFF,
      },
      MESSAGING_TEMPLATES: {
        title: t('common.menu.messagingTemplates'),
        url: routesPath.MESSAGING_TEMPLATES,
        code: routesPath.MESSAGING_TEMPLATES,
      },
      TAG_AND_WORKSPACE: {
        title: t('common.menu.tagAndWorkspace'),
        url: routesPath.TAG_AND_WORKSPACE,
        code: routesPath.TAG_AND_WORKSPACE,
      },
      PRICING: {
        title: t('common.menu.pricing'),
        url: routesPath.PRICING,
        code: routesPath.PRICING,
        icon: <GTGSIcon name="folder2-open" size={20} fill="currentColor" />,
      },
      CONTACT: {
        title: t('common.menu.contacts'),
        url: routesPath.CONTACTS,
        code: routesPath.CONTACTS,
      },
      COMPANIES: {
        title: t('common.menu.companies'),
        url: routesPath.COMPANIES,
        code: routesPath.COMPANIES,
      },
      TASKS: {
        title: t('common.menu.tasks'),
        url: routesPath.TASKS,
        code: routesPath.TASKS,
      },
      DEALS: {
        title: t('common.menu.deals'),
        url: routesPath.DEALS,
        code: routesPath.DEALS,
      },
      METRIC: {
        title: t('common.menu.metric'),
        url: routesPath.METRIC,
        code: routesPath.METRIC,
        icon: <GTGSIcon name="bar-chart" size={20} fill="currentColor" />,
      },
      SETTINGS: {
        title: t('common.menu.settings'),
        url: routesPath.SETTINGS,
        code: routesPath.SETTINGS,
        icon: <GTGSIcon name="setting" size={20} fill="currentColor" />,
      },
      ACCESS_LEVEL_CONTROL: {
        title: t('common.menu.accessLevelControl'),
        url: routesPath.ACCESS_LEVEL_CONTROL,
        code: routesPath.ACCESS_LEVEL_CONTROL,
        icon: <GTGSIcon name="setting" size={20} fill="currentColor" />,
      },
      USER_MANAGEMENT: {
        title: t('common.menu.userManagement'),
        url: routesPath.USER_MANAGEMENT,
        code: routesPath.USER_MANAGEMENT,
        icon: <GTGSIcon name="setting" size={20} fill="currentColor" />,
      },
      DELETE_WORKSPACE: {
        title: t('common.menu.DeleteWorkspace'),
        url: routesPath.DELETE_WORKSPACE,
        code: routesPath.DELETE_WORKSPACE,
        icon: <GTGSIcon name="setting" size={20} fill="currentColor" />,
      },
    };
  }, [t]);

  const _getLabel = useCallback((label: string, url?: string) => {
    if (url) {
      return <Link to={url}>{label}</Link>;
    }
    return label;
  }, []);

  const _menuItems = useMemo(() => {
    return [
      {
        key: _menuConstant.CONTENT_BUILDER.code,
        label: _getLabel(_menuConstant.CONTENT_BUILDER.title),
        icon: _menuConstant.CONTENT_BUILDER.icon,
        popupClassName: styles.popupContent,
        children: [
          {
            key: _menuConstant.LANDING_PAGES.code,
            label: _getLabel(
              _menuConstant.LANDING_PAGES.title,
              _menuConstant.LANDING_PAGES.url
            ),
          },
          {
            key: _menuConstant.EMAIL_BUILDER.code,
            label: _getLabel(
              _menuConstant.EMAIL_BUILDER.title,
              _menuConstant.EMAIL_BUILDER.url
            ),
            icon: _menuConstant.EMAIL_BUILDER.icon,
          },
        ],
      },
      // {
      //   key: _menuConstant.DASHBOARD.code,
      //   label: _getLabel(
      //     _menuConstant.DASHBOARD.title,
      //     _menuConstant.DASHBOARD.url
      //   ),
      //   icon: _menuConstant.DASHBOARD.icon,
      // },
      {
        key: _menuConstant.CRM.code,
        label: _getLabel(_menuConstant.CRM.title),
        icon: _menuConstant.CRM.icon,
        popupClassName: styles.popupContent,
        children: [
          {
            key: _menuConstant.CONTACT.code,
            label: _getLabel(
              _menuConstant.CONTACT.title,
              _menuConstant.CONTACT.url
            ),
          },
          {
            key: _menuConstant.COMPANIES.code,
            label: _getLabel(
              _menuConstant.COMPANIES.title,
              _menuConstant.COMPANIES.url
            ),
          },
          {
            key: _menuConstant.TASKS.code,
            label: _getLabel(
              _menuConstant.TASKS.title,
              _menuConstant.TASKS.url
            ),
          },
          {
            key: _menuConstant.DEALS.code,
            label: _getLabel(
              _menuConstant.DEALS.title,
              _menuConstant.DEALS.url
            ),
          },
        ],
      },
      {
        key: _menuConstant.MESSAGING_ROOT.code,
        label: _getLabel(_menuConstant.MESSAGING_ROOT.title),
        icon: _menuConstant.MESSAGING_ROOT.icon,
        popupClassName: styles.popupContent,
        children: [
          {
            key: _menuConstant.CHANNELS_INTEGRATION.code,
            label: _getLabel(
              _menuConstant.CHANNELS_INTEGRATION.title,
              _menuConstant.CHANNELS_INTEGRATION.url
            ),
          },
          {
            key: _menuConstant.MESSAGING.code,
            label: _getLabel(
              _menuConstant.MESSAGING.title,
              _menuConstant.MESSAGING.url
            ),
          },
        ],
      },
      // {
      //   key: _menuConstant.COMMERCE.code,
      //   label: _getLabel(
      //     _menuConstant.COMMERCE.title,
      //     _menuConstant.COMMERCE.url
      //   ),
      //   icon: _menuConstant.COMMERCE.icon,
      // },

      {
        key: _menuConstant.AUTOMATIONS.code,
        label: _getLabel(
          _menuConstant.AUTOMATIONS.title,
          _menuConstant.AUTOMATIONS.url
        ),
        icon: _menuConstant.AUTOMATIONS.icon,
      },
      {
        key: _menuConstant.MARKETING.code,
        label: _getLabel(_menuConstant.MARKETING.title),
        icon: _menuConstant.MARKETING.icon,
        children: [
          // {
          //   key: _menuConstant.CAMPAIGNS.code,
          //   label: _getLabel(
          //     _menuConstant.CAMPAIGNS.title,
          //     _menuConstant.CAMPAIGNS.url
          //   ),
          // },
          // {
          //   key: _menuConstant.EMAIL_MARKETING.code,
          //   label: _getLabel(
          //     _menuConstant.EMAIL_MARKETING.title,
          //     _menuConstant.EMAIL_MARKETING.url
          //   ),
          // },
          // {
          //   key: _menuConstant.SOCIAL.code,
          //   label: _getLabel(
          //     _menuConstant.SOCIAL.title,
          //     _menuConstant.SOCIAL.url
          //   ),
          // },
          {
            key: _menuConstant.ADS.code,
            label: _getLabel(_menuConstant.ADS.title, _menuConstant.ADS.url),
          },
          // {
          //   key: _menuConstant.FORMS.code,
          //   label: _getLabel(
          //     _menuConstant.FORMS.title,
          //     _menuConstant.FORMS.url
          //   ),
          // },
        ],
      },

      // {
      //   key: _menuConstant.REPORTING.code,
      //   label: _getLabel(
      //     _menuConstant.REPORTING.title,
      //     _menuConstant.REPORTING.url
      //   ),
      //   icon: _menuConstant.REPORTING.icon,
      //   hide: true,
      // },
      // {
      //   key: _menuConstant.LIBRARY.code,
      //   label: _getLabel(
      //     _menuConstant.LIBRARY.title,
      //     _menuConstant.LIBRARY.url
      //   ),
      //   icon: _menuConstant.LIBRARY.icon,
      //   popupClassName: styles.popupContent,
      //   hide: true,
      //   children: [
      //     {
      //       key: _menuConstant.MESSAGING_TEMPLATES.code,
      //       label: _getLabel(
      //         _menuConstant.MESSAGING_TEMPLATES.title,
      //         _menuConstant.MESSAGING_TEMPLATES.url
      //       ),
      //     },
      //     {
      //       key: _menuConstant.TAG_AND_WORKSPACE.code,
      //       label: _getLabel(
      //         _menuConstant.TAG_AND_WORKSPACE.title,
      //         _menuConstant.TAG_AND_WORKSPACE.url
      //       ),
      //     },
      //     {
      //       key: _menuConstant.STAFF.code,
      //       label: _getLabel(
      //         _menuConstant.STAFF.title,
      //         _menuConstant.STAFF.url
      //       ),
      //     },
      //   ],
      // },
      // {
      //   key: _menuConstant.PRICING.code,
      //   label: _getLabel(
      //     _menuConstant.PRICING.title,
      //     _menuConstant.PRICING.url
      //   ),
      //   icon: _menuConstant.PRICING.icon,
      //   hide: true,
      // },
      // {
      //   key: _menuConstant.METRIC.code,
      //   label: _getLabel(_menuConstant.METRIC.title, _menuConstant.METRIC.url),
      //   icon: _menuConstant.METRIC.icon,
      //   hide: true,
      // },

      // {
      //   key: _menuConstant.ADMINISTRATION.code,
      //   label: _getLabel(
      //     _menuConstant.ADMINISTRATION.title,
      //     _menuConstant.ADMINISTRATION.url
      //   ),
      //   icon: _menuConstant.ADMINISTRATION.icon,
      //   children: [
      //     {
      //       key: _menuConstant.USER_BEP.code,
      //       label: _getLabel(
      //         _menuConstant.USER_BEP.title,
      //         _menuConstant.USER_BEP.url
      //       ),
      //       icon: _menuConstant.USER_BEP.icon,
      //     }
      //   ]
      // }
      isOwner && {
        key: _menuConstant.SETTINGS.code,
        label: _getLabel(_menuConstant.SETTINGS.title),
        icon: _menuConstant.SETTINGS.icon,
        children: [
          {
            key: _menuConstant.ACCESS_LEVEL_CONTROL.code,
            label: _getLabel(
              _menuConstant.ACCESS_LEVEL_CONTROL.title,
              _menuConstant.ACCESS_LEVEL_CONTROL.url
            ),
          },
          {
            key: _menuConstant.USER_MANAGEMENT.code,
            label: _getLabel(
              _menuConstant.USER_MANAGEMENT.title,
              _menuConstant.USER_MANAGEMENT.url
            ),
          },

          {
            key: _menuConstant.DELETE_WORKSPACE.code,
            label: _getLabel(
              _menuConstant.DELETE_WORKSPACE.title,
              _menuConstant.DELETE_WORKSPACE.url
            ),
          },
        ],
      },
    ] as MenuItem[];
  }, [_menuConstant, _getLabel, isOwner]);

  return {
    constant: _menuConstant,
    items: _menuItems,
  };
};
