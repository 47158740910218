import AssetUpload from '@/components/AssetUpload';
import { GTGSIcon } from '@/components/GTGS';
import FormSetting from '@/pages/PageBuilder/components/form-builder/FormSetting';
import { useEditor, useNode } from '@craftjs/core';
import {
  Button,
  ColorPicker,
  Dropdown,
  Flex,
  Slider,
  Tooltip,
  Typography,
} from 'antd';
import React, { lazy, Suspense, useState } from 'react';
import Header from './Header';

const FormBuilder = lazy(
  () => import('@/pages/PageBuilder/components/form-builder/FormBuilder')
);

type TopBannerProps = {
  title: string;
  subTitle: string;
  description: string;
  formTitle: string;
  buttonText: string;
  logo: any;
  banner: any;
  themeColor?: string;
};

function TopBanner({
  title,
  subTitle,
  description,
  formTitle,
  buttonText,
  logo,
  banner,
  themeColor,
}: TopBannerProps) {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  return (
    <>
      <div
        // style={{
        //   backgroundImage: `url(${banner?.url})`,
        // }}
        className={`min-h-full py-20 pt-0 flex relative bg-center bg-cover bg-no-repeat`}
        ref={connect as unknown as React.RefObject<HTMLDivElement>}
        id={id}
      >
        <img
          src={banner?.url}
          alt="Banner"
          style={{
            opacity: banner?.opacity,
            filter: `blur(${banner?.blur}px)`,
          }}
          className={`absolute left-0 top-0 w-full h-full object-cover object-center -z-20`}
        />
        <div
          className={`absolute left-0 top-0 w-full h-full -z-10 bg-[${banner?.overlay}]`}
        />
        {/* <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div> */}
        <style>
          {`
            @media (max-width: 768px) {
              div[style] {
                background-image: url('/bg-2.png') !important; /* Use different background for mobile screens */
                background-position: left bottom !important;
              }
            }
          `}
        </style>

        <div className="w-full h-auto px-6 md:px-[150px]">
          <Header logo={logo?.url} />
          <div className="bg-transparent grid grid-cols-1 md:grid-cols-2 gap-0 items-center justify-end">
            <div
              className="md:h-[430px] max-w-[590px] text-[#0F172A]flex justify-start"
              ref={connect as unknown as React.RefObject<HTMLDivElement>}
            >
              <h1 className="font-light leading-tight mb-2">
                <span className="block text-[20px] xl:text-[44px]">
                  {subTitle}
                </span>
                <span className="block text-[30px] xl:text-[64px] font-bold tracking-tight">
                  {title}
                </span>
              </h1>
              <p className="w-full font-normal md:text-[13px] xl:text-[18px] break-words">
                {description}
              </p>
            </div>

            <div className=" w-[90%] justify-center md:w-full flex md:justify-end ">
              <div className="w-full max-w-full  p-6 md:p-8 bg-[#FFFFFF80] rounded-3xl ">
                <h2 className="text-xl md:text-2xl font-bold mb-6 text-center md:text-left">
                  {formTitle}
                </h2>
                <Suspense fallback={<div>Loading...</div>}>
                  <FormBuilder
                    buttonText={buttonText}
                    themeColor={themeColor}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

TopBanner.defaultProps = {
  title: 'With GTG CRM',
  subTitle: 'Unleash Your Potential',
  description:
    'Effortlessly connect your data, teams, and customers on a single AI-driven customer platform that scales with your business.',
  formTitle: 'Get Your Free Demo Now!',
  buttonText: 'Register',
  logo: {
    url: '/images/logo/logo-gtgcrm.png',
    name: 'GTG CRM',
  },
  banner: {
    url: '/bg-water.png',
    name: 'GTG Banner',
    opacity: 1,
    blur: 0,
  },
};

const TopBannerSettings = () => {
  const { nodes, query, actions } = useEditor((state, query) => {
    return {
      nodes: state.nodes,
    };
  });

  const {
    actions: { setProp },
    title,
    subTitle,
    description,
    formTitle,
    buttonText,
    logo,
    banner,
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    description: node.data.props.description,
    formTitle: node.data.props.formTitle,
    buttonText: node.data.props.buttonText,
    logo: node.data.props.logo,
    banner: node.data.props.banner,
  }));
  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Logo</label>
        <Flex className="w-full" align="center" justify="space-between">
          <span>{logo?.name ? logo?.name : 'GTG CRM'}</span>
          <AssetUpload
            onSelect={(file) => {
              setProp((props: { logo: any }) => (props.logo = file), 1000);
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
        </Flex>
      </div>
      <div className="mb-5">
        <Flex className="mb-2" align="center" justify="space-between">
          <label className="block font-bold text-gray-700">Banner</label>
          <Tooltip title="Advanced settings">
            <Dropdown
              overlayStyle={{ width: 254 }}
              trigger={['click']}
              placement="bottomRight"
              dropdownRender={() => (
                <Flex
                  vertical
                  className="w-full bg-gray-100 rounded-md p-4"
                  gap={8}
                >
                  <Flex justify="space-between" align="center">
                    Opacity
                    <Slider
                      className="w-3/4"
                      min={0}
                      max={1}
                      step={0.01}
                      defaultValue={banner.opacity}
                      onChange={(value) => {
                        setProp(
                          (props: { banner: any }) =>
                            (props.banner.opacity = value),
                          1000
                        );
                      }}
                    />
                  </Flex>
                  <Flex justify="space-between" align="center">
                    Blur
                    <Slider
                      className="w-3/4"
                      min={0}
                      max={20}
                      step={1}
                      tooltip={{ formatter: (value) => `${value}px` }}
                      defaultValue={banner.blur}
                      onChange={(value) => {
                        setProp(
                          (props: { banner: any }) =>
                            (props.banner.blur = value),
                          1000
                        );
                      }}
                    />
                  </Flex>
                  <Flex align="center" gap={8}>
                    Overlay color
                    <ColorPicker
                      allowClear
                      onChange={(value, css) => {
                        setProp(
                          (props: { banner: any }) =>
                            (props.banner.overlay = css),
                          1000
                        );
                      }}
                    />
                  </Flex>
                </Flex>
              )}
            >
              <GTGSIcon
                name="magic"
                size={18}
                className="cursor-pointer"
                // onClick={() => setOpenDropdown(!openDropdown)}
              />
            </Dropdown>
          </Tooltip>
        </Flex>
        <Flex className="w-full" align="center" justify="space-between">
          <Typography.Text
            style={{ width: 200 }}
            ellipsis={{ tooltip: banner?.name }}
          >
            {banner?.name}
          </Typography.Text>
          <AssetUpload
            onSelect={(file) => {
              setProp(
                (props: { banner: any }) =>
                  (props.banner = { ...props.banner, ...file }),
                1000
              );
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
        </Flex>
        {/* <Flex align="center" justify="space-between">
          Opacity
          <Slider
            className="w-3/4"
            min={0}
            max={1}
            step={0.01}
            defaultValue={banner.opacity}
            onChange={(value) => {
              setProp(
                (props: { banner: any }) => (props.banner.opacity = value),
                1000
              );
            }}
          />
        </Flex>
        <Flex align="center" justify="space-between">
          Blur
          <Slider
            className="w-3/4"
            min={0}
            max={20}
            step={1}
            tooltip={{ formatter: (value) => `${value}px` }}
            defaultValue={banner.blur}
            onChange={(value) => {
              setProp(
                (props: { banner: any }) => (props.banner.blur = value),
                1000
              );
            }}
          />
        </Flex> */}
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={title}
          onChange={(e) => {
            setProp(
              (props: { title: string }) => (props.title = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Sub Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={subTitle}
          onChange={(e) => {
            setProp(
              (props: { subTitle: string }) =>
                (props.subTitle = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Description
        </label>
        <textarea
          rows={8}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={description}
          onChange={(e) => {
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Form Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={formTitle}
          onChange={(e) => {
            setProp(
              (props: { formTitle: string }) =>
                (props.formTitle = e.target.value),
              1000
            );
          }}
        />
      </div>
      <FormSetting />
      <div className="my-5">
        <label className="block mb-2 font-bold text-gray-700">Button</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={buttonText}
          onChange={(e) => {
            setProp(
              (props: { buttonText: string }) =>
                (props.buttonText = e.target.value),
              1000
            );
          }}
        />
      </div>
    </div>
  );
};

TopBanner.craft = {
  props: TopBanner.defaultProps,
  related: {
    settings: TopBannerSettings,
  },
  displayName: 'Top Banner',
};

export default TopBanner;
