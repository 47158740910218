import httpService from './httpService';
import config from '../config';
import { customResponsePagination } from '@/helpers';
import UserService from './UserService';
import axios from 'axios';

const getBaseUrl = (version: 'v1' | 'v2' = 'v1') => `${config.gatewayUrl}/${version}/assets`;

export const getAssets = async (params: any & { version?: 'v1' | 'v2' }) => {
  const { version, ...queryParams } = params;
  const baseUrl = getBaseUrl(version);
  const { data } = await httpService.get(`${baseUrl}`, { params: queryParams });
  return data;
};

interface StartUploadFileRequest {
  key: string;
  isPrivate: boolean;
  fileSizeBytes: number;
  version?: 'v1' | 'v2';
  actionType?: string;
  additionalData?: any;
}

export const startUploadFile = async (body: StartUploadFileRequest) => {
  const { version, ...requestBody } = body;
  const baseUrl = getBaseUrl(version);
  const { data } = await httpService.post(`${baseUrl}/start-upload`, requestBody);
  return data;
};

interface ProcessUploadRequest {
  url: string;
  file: any;
  headers: any;
  method: string;
}

export const processUpload = async (body: ProcessUploadRequest) => {
  const { url, file, headers, method } = body;
  await axios({
    url: url,
    method: method,
    data: file,
    headers: {
      'Content-Type': file.type,
      ...headers.reduce((acc, header) => {
        acc[header.key] = header.values[0];
        return acc;
      }, {}),
    },
  });
};

interface CompleteUploadFileRequest {
  fileSizeBytes: number;
  contentType: string;
  isPrivate: boolean;
  name: string;
  key: string;
  version?: 'v1' | 'v2';
}

export const completeUploadFile = async (body: CompleteUploadFileRequest) => {
  const { version, ...requestBody } = body;
  const baseUrl = getBaseUrl(version);
  const { data } = await httpService.post(`${baseUrl}/complete-upload`, requestBody);
  return data;
};

export const getDetailsAsset = async (assetId: string, version?: 'v1' | 'v2') => {
  const baseUrl = getBaseUrl(version);
  const { data } = await httpService.get(`${baseUrl}/${assetId}`);
  return data;
};

export const startProfilePictureUploadFile = async (body) => {
  const { data } = await httpService.post(
    `${getBaseUrl('v1')}/profile-picture/start-upload`,
    body
  );
  return data;
};

export const completeProfilePictureUploadFile = async (body) => {
  const { data } = await httpService.post(
    `${getBaseUrl('v1')}/profile-picture/complete-upload`,
    body
  );
  return data;
};

export const getProfilePictureDetailsAsset = async (assetId) => {
  const { data } = await httpService.get(
    `${getBaseUrl('v1')}/profile-picture/${assetId}`
  );
  return data;
};

export const connectFileUploadAWS = async (url, fileSize, file) => {
  const token = UserService.getToken();
  const headers = {
    'Content-Length': fileSize,
  };
  const response = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: headers,
  });

  if (!response.ok) {
    throw new Error(`Failed to upload file: ${response.statusText}`);
  }

  return response;
};

const assetsManagementService = {
  startUploadFile,
  completeUploadFile,
  getAssets,
  getDetailsAsset,
  startProfilePictureUploadFile,
  completeProfilePictureUploadFile,
  getProfilePictureDetailsAsset,
  connectFileUploadAWS,
};

export default assetsManagementService;
