import { useEditor } from '@craftjs/core';
import lz from 'lzutf8';
import { useEffect } from 'react';
import '@/styles/app.styles.scss';

const dataChanged = process.env.REACT_APP_DATA_CHANGED || '';

const LoadPage = () => {
  const { actions } = useEditor();

  useEffect(() => {
    try {
      const json = lz.decompress(lz.decodeBase64(dataChanged));
      actions.deserialize(json);

      const fetchData = async () => {
        const stateToLoad = await fetch(
          `https://api.gtgcrm.com/v1/pages/landing-pages/versions/latest`
        );

        const responseText = await stateToLoad.json();

        // Add the script with siteId from the response
        if (responseText.siteID) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.innerHTML = `
              var _paq = window._paq = window._paq || [];
              _paq.push(['trackPageView']);
              _paq.push(['enableLinkTracking']);
              (function() {
                var u="https://pmetric.gtgcrm.com/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '${responseText.siteID}']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
              })();
            `;
          document.head.appendChild(script);
        }
      };

      fetchData();
    } catch (error) {
      console.error('Error decompressing or deserializing data:', error);
    }
  }, [actions]);

  return <div>{null}</div>;
};

export default LoadPage;
