import { GTGSIcon, GTGSTypography } from '@/components/GTGS';
import useJsonSchema from '@/pages/PageBuilder/store/useJsonSchema';
import { usePageBuilder } from '@/pages/PageBuilder/store/usePageBuilderStore';
import { PlusOutlined } from '@ant-design/icons';
import { useEditor, useNode } from '@craftjs/core';
import { Button, Divider, Flex, Form, Input, Switch } from 'antd';
import { useEffect, useMemo, useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import SchemaProperties from './SchemaProperties';
import { formatFieldName } from '@/pages/CRM/helpers/stringHelper';

const SelectSettings = () => {
  const [form] = Form.useForm();
  const nameField = Form.useWatch('name', form);

  const { setFormSettingVisible, formSettingVisible } = usePageBuilder(
    useShallow((state) => ({
      setFormSettingVisible: state.setFormSettingVisible,
      formSettingVisible: state.formSettingVisible,
    }))
  );
  const { actions } = useEditor();

  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  const { jsonSchema } = useJsonSchema();

  const propertyData = useMemo(
    () => (nameField && jsonSchema ? jsonSchema[nameField] : undefined),
    [jsonSchema, nameField]
  );

  const isEnum = propertyData?.enum;

  const closeModal = useCallback(() => {
    setFormSettingVisible(false);
    actions.clearEvents();
  }, [setFormSettingVisible, actions]);

  const handleSubmit = useCallback(
    (values) => {
      setProp((props) => {
        Object.keys(values).forEach((key) => {
          props[key] = values[key];
        });
        props['data-array'] = propertyData?.type === 'array';
        props['data-enum'] = !!propertyData?.enum;
      }, 1000);
      closeModal();
    },
    [setProp, closeModal, propertyData?.type, propertyData?.enum]
  );

  useEffect(() => {
    if (propertyData?.enum) {
      form.setFieldsValue({
        options: propertyData.enum,
      });
    }
    if (!formSettingVisible) {
      form.resetFields();
    }
  }, [propertyData, form, setProp, formSettingVisible]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...props,
      }}
      onFinish={handleSubmit}
      onValuesChange={(changedValues) => {
        if (changedValues.name) {
          form.setFieldsValue({
            label: formatFieldName(changedValues.name),
            placeholder: formatFieldName(changedValues.name),
          });
        }
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please select the name!',
          },
        ]}
      >
        <SchemaProperties defaultName={name} />
      </Form.Item>
      <Flex gap={8} align="center">
        <Form.Item name="isRequired" valuePropName="checked">
          <Switch />
        </Form.Item>
        <GTGSTypography.Text strong className="mb-3">
          Make this field required
        </GTGSTypography.Text>
      </Flex>
      <Form.Item label="Label" name="label">
        <Input />
      </Form.Item>
      <Form.Item
        extra={
          isEnum
            ? `${formatFieldName(nameField)} is an enum and the options can't be modified.`
            : null
        }
        label="Field Options"
        name="options"
        rules={[
          {
            validator: async (_, options) => {
              if (!options || options.length < 1) {
                return Promise.reject(
                  new Error('Please add at least one option!')
                );
              }
            },
          },
        ]}
      >
        <Form.List name="options">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <>
                  <Flex
                    key={key}
                    align="center"
                    gap={16}
                    className="px-2 py-1 hover:bg-slate-100"
                  >
                    <Flex gap={0}>
                      <GTGSIcon name="dots-three-vertical" size="small" />
                    </Flex>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name]}
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the option!',
                        },
                      ]}
                    >
                      <GTGSTypography.Paragraph
                        editable={
                          !isEnum
                            ? {
                                onChange: (value) => {
                                  if (!value) return;
                                  form.setFieldsValue({
                                    options: { [name]: value },
                                  });
                                },
                                triggerType: ['text'],
                              }
                            : false
                        }
                        style={{ margin: 0, width: '100%' }}
                      >
                        {formatFieldName(form.getFieldValue(['options', name]))}
                      </GTGSTypography.Paragraph>
                    </Form.Item>
                    {!isEnum && (
                      <Button
                        type="link"
                        onClick={() => remove(name)}
                        style={{ padding: 0 }}
                      >
                        Delete
                      </Button>
                    )}
                  </Flex>
                  <Divider style={{ margin: 0 }} />
                </>
              ))}
              {!isEnum && (
                <Button
                  type="link"
                  onClick={() => add('New Option')}
                  icon={<PlusOutlined />}
                >
                  ADD MORE
                </Button>
              )}
            </>
          )}
        </Form.List>
      </Form.Item>
      <Flex gap={8}>
        <Button
          className="w-full"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="w-full">
          Update
        </Button>
      </Flex>
    </Form>
  );
};

export default SelectSettings;
