import { Editor, Element, Frame, useNode, Resolver } from '@craftjs/core';
import React from 'react';
import { HeaderSetting } from './components/HeaderSetting';
import Iframe from './components/Iframe'; // Import the IFrame component
import { SettingsPanel } from './components/SettingsPanel';

import { getLandingPageState } from '@/services/pageBuilderService';
import { Layout } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import InitPageContent from './components/InitPageContent';
import LoadPage from './components/LoadPage';
import { RenderNode } from './components/RenderNode';
import { usePageBuilder } from './store/usePageBuilderStore';

import * as FormBuilder from './components/form-builder';
import CrmTheme from './themes/theme-1';
import CosmeticTheme from './themes/theme-2';

const { Header: AntdHeader, Sider, Content } = Layout;

const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100%',
  position: 'fixed',
  insetInlineStart: 0,
  top: 80,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
  width: '100%',
  padding: 16,
  paddingTop: 0,
};

export const Container = ({ children }) => {
  return <div className="h-full">{children}</div>;
};

// TODO: mapping with BE
const themeMapping = {
  ['theme-1']: CrmTheme,
  ['theme-2']: CosmeticTheme,
};

Container.craft = {
  displayName: 'Container',
  rules: {
    canMoveIn: (incomingNodes) =>
      incomingNodes.every(
        (incomingNode) => !incomingNode.data.displayName.includes('GTGFORM')
      ),
  },
};

export const FormBox = ({ children }) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <div
      className="grid gap-x-6 mb-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2"
      ref={(ref) => ref && connect(ref)}
    >
      {children}
    </div>
  );
};

FormBox.craft = {
  displayName: 'FormBox Container',
  rules: {
    canMoveIn: (incomingNodes) =>
      incomingNodes.every((incomingNode) =>
        incomingNode.data.displayName.includes('GTGFORM')
      ),
  },
};

const PageBuilder: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useQuery(['getLatestState'], () =>
    getLandingPageState(id || '', 'latest')
  );

  const themeUsing = React.useMemo(() => {
    const themeCode = data?.templateCode;
    return themeMapping[themeCode];
  }, [data?.templateCode]);

  if (data && !data?.dataChanged) {
    navigate('..' + '/select-template', { relative: 'path' });
  }

  const { device } = usePageBuilder(
    useShallow((state) => ({
      device: state.device,
    }))
  );

  return !data?.dataChanged || !data?.templateCode ? null : (
    <div className="min-h-screen relative">
      <Editor
        resolver={{
          // ...(themeUsing ? themeUsing : { CrmTheme, CosmeticTheme }),
          ...CrmTheme,
          ...CosmeticTheme,
          ...FormBuilder,
          LoadPage,
          Container,
          InitPageContent,
          Iframe,
          FormBox,
        }}
        onRender={RenderNode}
      >
        <Layout hasSider>
          <AntdHeader
            style={{
              position: 'fixed',
              width: '100%',
              height: '70px',
              top: 0,
              zIndex: 100,
              left: 0,
            }}
          >
            <HeaderSetting />
          </AntdHeader>
          <Layout hasSider style={{ height: '100%' }}>
            <Sider style={siderStyle} width={320}>
              <SettingsPanel />
            </Sider>
            <Layout
              style={{
                marginInlineStart: 320,
                height: '100%',
                padding: '70px 24px 0px 0px',
              }}
              className="craftjs-renderer"
            >
              <Content
                style={{
                  width: device === 'desktop' ? '100%' : '430px',
                  height: 'calc(100vh - 70px)',
                  margin: 'auto',
                  transition: 'width 0.3s ease-in-out',
                  position: 'relative',
                }}
              >
                <Iframe>
                  <Frame>
                    <Element canvas id="root" is={Container}>
                      <InitPageContent />
                    </Element>
                  </Frame>
                </Iframe>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </Editor>
    </div>
  );
};

export default PageBuilder;
