import { GTGSTypography } from '@/components/GTGS';
import { formatFieldName } from '@/pages/CRM/helpers/stringHelper';
import useEditorState from '@/pages/PageBuilder/store/useEditorState';
import useJsonSchema from '@/pages/PageBuilder/store/useJsonSchema';
import { usePageBuilder } from '@/pages/PageBuilder/store/usePageBuilderStore';
import { useNode } from '@craftjs/core';
import { Button, Flex, Form, Input, InputNumber, Switch } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import SchemaProperties from './SchemaProperties';

const InputSettings = () => {
  const [form] = Form.useForm();
  const nameField = Form.useWatch('name', form);

  const { requiredProperties, jsonSchema } = useJsonSchema();
  const { actions, activeTopBannerNode } = useEditorState();

  const { setFormSettingVisible, formSettingVisible } = usePageBuilder(
    useShallow((state) => ({
      setFormSettingVisible: state.setFormSettingVisible,
      formSettingVisible: state.formSettingVisible,
    }))
  );

  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  const propertyData = useMemo(
    () => (nameField && jsonSchema ? jsonSchema[nameField] : undefined),
    [jsonSchema, nameField]
  );

  useEffect(() => {
    if (!formSettingVisible) {
      form.resetFields();
    }
  }, [propertyData, setProp, form, formSettingVisible]);

  const renderFields = useCallback(() => {
    if (!propertyData) return null;

    if (propertyData?.items?.pattern) {
      form.setFieldValue('pattern', propertyData?.items?.pattern);
      return (
        <Form.Item label="Pattern" name="pattern">
          <Input size="large" disabled />
        </Form.Item>
      );
    }

    if (propertyData?.format || propertyData?.items?.format) {
      form.setFieldValue(
        'format',
        propertyData?.format || propertyData?.items?.format
      );
      return (
        <Form.Item label="format" name="format" hidden>
          <Input size="large" disabled />
        </Form.Item>
      );
    }

    return (
      <>
        <Form.Item label="Min Length" name="minLength">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item label="Max Length" name="maxLength">
          <InputNumber className="w-full" />
        </Form.Item>
      </>
    );
  }, [propertyData, form]);

  const closeModal = useCallback(() => {
    setFormSettingVisible(false);
    actions.clearEvents();
  }, [setFormSettingVisible, actions]);

  const handleSubmit = useCallback(
    (values) => {
      setProp((props) => {
        Object.keys(values).forEach((key) => {
          props[key] = values[key];
        });
        props['data-array'] = propertyData?.type === 'array';
      }, 1000);
      closeModal();
    },
    [setProp, closeModal, propertyData?.type]
  );

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...props,
      }}
      onFinish={handleSubmit}
      onValuesChange={(changedValues) => {
        if (changedValues.name) {
          form.setFieldsValue({
            label: formatFieldName(changedValues.name),
            placeholder: formatFieldName(changedValues.name),
          });
        }
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please select the name!',
          },
        ]}
      >
        <SchemaProperties
          disabled={nameField && requiredProperties?.includes(nameField)}
        />
      </Form.Item>
      <Flex gap={8} align="center">
        <Form.Item name="isRequired" valuePropName="checked">
          <Switch
            disabled={nameField === 'first_name' || nameField === 'last_name'}
          />
        </Form.Item>
        <GTGSTypography.Text strong className="mb-3">
          Make this field required
        </GTGSTypography.Text>
      </Flex>
      <Form.Item label="Label" name="label">
        <Input />
      </Form.Item>
      <Form.Item label="Placeholder" name="placeholder">
        <Input />
      </Form.Item>

      {renderFields()}
      <Flex gap={8}>
        <Button
          className="w-full"
          onClick={() => {
            closeModal();
            activeTopBannerNode();
          }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="w-full">
          Update
        </Button>
      </Flex>
    </Form>
  );
};

export default InputSettings;
