import {
  completeUploadFile,
  processUpload,
  startUploadFile,
} from '@/services/assetsManagementService';
import { uuidv4 } from '@/utils/commonUtils';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, UploadFile } from 'antd';
import { useState } from 'react';
import { GTGSButton } from '../GTGS';

import { useQueryClient } from 'react-query';
import styled from 'styled-components';

const StyledUpload = styled(Upload)`
  width: auto;
  .ant-upload {
    min-height: unset;
  }
`;

const UploadButton = ({ version = 'v1' }: { version?: 'v1' | 'v2' }) => {
  const queryClient = useQueryClient();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

  const handleUpload = async ({ file }) => {
    const key = uuidv4();
    try {
      // Step 1: Get presigned URL
      const presignedUrlResponse = await startUploadFile({
        key,
        isPrivate: false,
        fileSizeBytes: file.size,
        version
      });

      // Step 2: Upload file to S3
      await processUpload({ ...presignedUrlResponse, file });

      // Step 3: Inform backend
      await completeUploadFile({
        fileSizeBytes: file.size,
        contentType: file.type,
        isPrivate: false,
        name: file.name,
        key,
      });

      queryClient.invalidateQueries('assets');

      message.success(`File uploaded successfully.`);
    } catch (error) {
      message.error(`File upload failed.`);
    }
  };

  return (
    <StyledUpload
      customRequest={handleUpload}
      fileList={fileList}
      showUploadList={false}
      onChange={({ fileList }) => setFileList(fileList)}
    >
      <GTGSButton type="primary" icon={<UploadOutlined />}>
        Upload
      </GTGSButton>
    </StyledUpload>
  );
};

export default UploadButton;
