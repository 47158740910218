import { GTGSAvatar, GTGSPhoneNumber } from '@/components/GTGS';
import config from '@/config';
import { customResponsePagination } from '@/helpers';
import { timeToNow } from '@/helpers/dayjsHelpers';
import { IMessage } from '@/pages/Messaging/types';
import { uuidv4 } from '@/utils/commonUtils';
import { getCookie, setCookie } from '@/utils/cookieUtils';
import { handleScrollMessage } from '@/utils/mesage';
import { Button, Col, Form, Input, message, Row, Spin } from 'antd';
import uniqBy from 'lodash/uniqBy';
import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FiMinus } from 'react-icons/fi';
import { useInfiniteQuery } from 'react-query';
import { io } from 'socket.io-client';

const URL = `${config.socket.SOCKET_API_URL}`;
const baseUrl = `${config.gatewayUrl}/v1`;

// Setup socket URL of server
const socket = io(URL);

// ---------------------------------------------------------------------------

const textMessagesDefault =
  'Hello, to better assist you, please provide your name and email. Thank you!';

function ChatMessageInformationUser(
  fullName: string,
  email: string,
  phone: string
) {
  return `
    Hello, to better assist you, please provide your name and email. Thank you!<br/><br/>
    <span style="font-weight: bold;">Full name:</span> ${fullName}<br/>
    <span style="font-weight: bold;">Email:</span> ${email}${phone ? `<br/><span style="font-weight: bold;">Phone:</span> ${phone}` : ''}
  `;
}

const MessageDefault = {
  messageType: 'outgoing',
  messageHtml: textMessagesDefault,
  messageContent: textMessagesDefault,
  createdAt: Math.floor(Date.now() / 1000).toString(),
  messageId: uuidv4(),
};

// ===========================================================================
// MAIN
// ===========================================================================

interface ChatWindowProps {
  onClose: () => void;
}

const ChatWindow: FC<ChatWindowProps> = ({ onClose }) => {
  const [form] = Form.useForm();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [input, setInput] = useState<string>('');
  const [formVisible, setFormVisible] = useState<boolean>(true);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [infoUser, setInfoUser] = useState<any>({
    contactId: '',
    workspaceId: '',
  });
  const refSocketId = useRef<string>('');
  const chatRef = useRef<HTMLDivElement>(null);

  const infoUserCookie = useMemo(() => {
    const info = getCookie('liveChatCookie');
    return info ? JSON.parse(info) : null;
  }, []);

  // CHECK COOKIE
  useEffect(() => {
    if (infoUserCookie) {
      setInfoUser(infoUserCookie);
      setIsInputDisabled(false);
    }
  }, [infoUserCookie]);

  // CHECK INFO USER
  useEffect(() => {
    if (
      !infoUser?.contactId &&
      !infoUser?.workspaceId &&
      !getCookie('liveChatCookie')
    ) {
      setMessages([MessageDefault]);
    }
  }, [infoUser?.contactId, infoUser?.workspaceId]);

  // FETCH MESSAGES
  const {
    data,
    isFetching,
    fetchNextPage,
    isLoading: isLoadingChatList,
  } = useInfiniteQuery<any>(
    ['getChatMessages', { contact: infoUser?.contactId }],
    ({ pageParam = 1 }) => {
      return fetch(
        `${baseUrl}/messaging/live-chat?contact=${infoUser?.contactId}&page=${pageParam}&pageSize=${20}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Grpc-Metadata-X-Workspace-ID': infoUser?.workspaceId,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setIsLoading(false);
          return {
            data: customResponsePagination({
              content: data?.data?.message || [],
              totalElementsCount: Number(data?.data?.totalElementsCount || 0),
              currentPage: pageParam || 1,
              pageSize: 20,
            }),
            success: data.success,
          };
        })
        .catch((error) => {
          setIsLoading(false);
          message.error('Something went wrong, please try again later');
        });
    },
    {
      keepPreviousData: false,
      enabled: !!infoUser?.contactId && !!infoUserCookie?.contactId,
      cacheTime: 0,
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.hasNext
          ? lastPage?.data?.currentPage + 1
          : undefined;
      },
    }
  );

  useEffect(() => {
    if(chatRef.current)
    setTimeout(() => {
      handleScrollMessage(chatRef.current);
    }, 500);
  }, []);

  const messagesData: IMessage[] = useMemo(() => {
    const messagesPage = data?.pages.flatMap((page) => page?.data?.content)?.reverse() || [];
    return uniqBy([...messagesPage, ...(messages ?? [])], 'messageId').filter(
      (msg: IMessage, index: number, self: IMessage[]) => !!msg &&
        index === self.findIndex((m: IMessage) => m.createdAt === msg.createdAt || (m.messageContent === msg.messageContent && m.messageType === msg.messageType && m.messageType === "incoming" && Number((m?.createdAt || 0)) >= (Number((msg?.createdAt || 0)) + 1)))
    );
  }, [data?.pages, messages]);

  // SCROLL TO BOTTOM
  useEffect(() => {
    const handleScroll = () => {
      const elementMessage = chatRef?.current;
      if (elementMessage) {
        const { scrollTop, scrollHeight, clientHeight } = elementMessage;
        const scrollPercentage =
          (scrollTop / (scrollHeight - clientHeight)) * 100;

        if (scrollPercentage <= 30 && !isFetching) {
          fetchNextPage();
        }
      }
    };

    const elementMessage = chatRef?.current;

    if (elementMessage) {
      elementMessage.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (elementMessage) {
        elementMessage.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchNextPage, isFetching]);

  // CONNECT SOCKET
  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, []);

  useEffect(() => {
    if ((infoUserCookie?.contactId || infoUser?.contactId) && socket.connected) {
      socket.emit('lpSubscribeLiveChat', {
        contactId: infoUserCookie?.contactId || infoUser?.contactId,
        source: 'lpVisitor',
      });
    }
  }, [infoUserCookie?.contactId, infoUser?.contactId]);

  // SUBSCRIBE LIVE CHAT
  useEffect(() => {
    socket.on('connect', () => {
      refSocketId.current = socket.id || '';
      socket.emit('lpSubscribeLiveChat', {
        contactId: infoUserCookie?.contactId || infoUser?.contactId,
        source: 'lpVisitor',
      });
    });
    return () => {
      if (socket.connected && refSocketId.current) {
        console.log('disconnect');
        refSocketId.current = '';
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUserCookie?.contactId || infoUser?.contactId]);

  // HANDLE MESSAGE
  useEffect(
    () => {
      const handleServerMessage = (
        accountId: string,
        contactId: string,
        { message: messageServer }: { message: IMessage }
      ) => {
        console.log('websocket --> active');
        if (
          infoUser?.contactId &&
          messageServer?.contactId === infoUser?.contactId &&
          messageServer?.messageType === 'outgoing'
        ) {
          const messageNew: IMessage = {
            ...messageServer,
            messageHtml: messageServer?.messageHtml || '',
            messageContent: messageServer?.messageContent || '',
          };

          setMessages((prevMessages) => {
            const updatedMessages: IMessage[] = [
              ...prevMessages,
              messageNew,
            ].filter(
              (msg, index, self) =>
                !!msg && index === self.findIndex((m) => m.createdAt === msg.createdAt)
            );
            return updatedMessages;
          });

          setTimeout(() => handleScrollMessage(chatRef.current), 500);
        }
      };

      socket.on('serverToClientBrowser', handleServerMessage);

      return () => {
        socket.off('serverToClientBrowser', handleServerMessage);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoUserCookie?.contactId, infoUser?.contactId]
  );

  // CREATE MESSAGE
  const createMessage = useCallback(
    (workspaceId: string, contactId: string, content: string) => {
      setIsLoading(true);
      const payload = {
        contact_id: contactId,
        content: content || '',
        channel: 'live-chat',
        source: '',
      };
      fetch(`${baseUrl}/messaging/messages/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Grpc-Metadata-X-Workspace-ID': workspaceId,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setIsLoading(false);
          setMessages((prevMessages) => {
            const updatedMessages: IMessage[] = [
              ...prevMessages,
              {
                ...MessageDefault,
                messageHtml: content,
                messageContent: content,
                createdAt: Math.floor(Date.now() / 1000).toString(),
                messageId: uuidv4(),
                messageType: 'incoming',
              }
            ];
            return updatedMessages;
          });

          setTimeout(() => handleScrollMessage(chatRef.current), 500);
        })
        .catch((error) => {
          setIsLoading(false);
          message.error('Something went wrong, please try again later');
        });
    },
    []
  );

  // HANDLE FORM SUBMIT
  const handleFormSubmit = (values: any) => {
    setIsLoading(true);
    const payload = {
      properties: {
        first_name: values?.firstName || '',
        last_name: values?.lastName || '',
        email: [values?.email || ''],
        ...(values?.phone?.trim() ? { phone_numbers: [values?.phone] } : {}),
      },
    };

    const fullName = `${values?.firstName || ''} ${values?.lastName || ''}`;

    const dataMessageNew = [
      {
        ...MessageDefault,
        messageHtml: ChatMessageInformationUser(
          fullName || '',
          values?.email || '',
          values?.phone || ''
        ),
        messageContent: ChatMessageInformationUser(
          fullName || '',
          values?.email || '',
          values?.phone || ''
        ),
      },
      {
        ...MessageDefault,
        messageHtml:
          "Thank you for reaching out! We'll get back to you shortly.",
        messageContent:
          "Thank you for reaching out! We'll get back to you shortly.",
      },
    ];

    fetch(`${baseUrl}/crm/cta`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.status === "failure") {
          message.error('Something went wrong, please try again later');
          return;
        }

        if (socket.connected) {
          socket.emit('lpSubscribeLiveChat', {
            contactId: data?.contactId,
            source: 'lpVisitor',
          });
        } else {
          socket.connect().emit('lpSubscribeLiveChat', {
            contactId: data?.contactId,
            source: 'lpVisitor',
          });
        }
        setMessages(dataMessageNew);
        setCookie(
          'liveChatCookie',
          JSON.stringify({
            contactId: data?.contactId || "",
            workspaceId: data?.workspaceId || "",
          })
        );
        setInfoUser({
          contactId: data?.contactId || "",
          workspaceId: data?.workspaceId || "",
        });
        setFormVisible(false);
        setIsInputDisabled(false);
        form.resetFields();
        message.success('Thank you for your registration');
        return;
      })
      .catch((error) => {
        setIsLoading(false);
        form.resetFields();
        message.error('Something went wrong, please try again later');
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSendMessage = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (input.trim()) {
        createMessage(infoUser?.workspaceId, infoUser?.contactId, input);
        setInput('');
      }
    },
    [createMessage, infoUser?.contactId, infoUser?.workspaceId, input]
  );

  return (
    <div className="flex flex-col bg-white rounded-3xl overflow-hidden shadow-lg w-[400PX] max-w-md h-[600px] mb-[20%]">
      {/* Header */}
      <div className="bg-blue-600 text-white p-4 flex items-center">
        <div className="flex items-center space-x-2 flex-grow">
          <GTGSAvatar
            src="/chat-support.png"
            alt=" "
            className="border border-white border-1"
            variant="image"
            type="withLine"
            size={44}
          />
          <div className="flex flex-col">
            <h3 className="font-semibold text-[20px] text-[#FFFFFF]">
              Chat with us
            </h3>
            <div className="flex items-center -mt-3">
              <span className="text-green-500 text-lg">●</span>
              <span className="text-sm font-normal text-[#00BE41]">Online</span>
            </div>
          </div>
        </div>
        <button
          onClick={onClose}
          className="flex items-center justify-center w-6 h-6 bg-transparent border-[1.5px] border-white rounded-full"
        >
          <FiMinus className="text-white w-4 h-4" />
        </button>
      </div>
      <div
        className="flex-1 p-4 overflow-y-auto space-y-4 scrollable"
        ref={chatRef}
      >
        <Spin spinning={isLoading || isLoadingChatList || isFetching}>
          {messagesData.map((message, index) => (
            <div
              key={index}
              className={`flex items-start space-x-3 ${message?.messageType === 'incoming' ? 'justify-end' : 'justify-start'}`}
            >
              {message?.messageType === 'outgoing' && (
                <img
                  src="/chat-support.png"
                  alt="Support Avatar"
                  className="w-8 h-8 rounded-full mt-5"
                />
              )}
              <div className={`flex flex-col gap-1`}>
                <div className="text-xs text-gray-500 self-end">
                  {message?.createdAt
                    ? timeToNow(
                      new Date(Number(message?.createdAt ?? 0) * 1000)
                    )
                    : ''}
                </div>
                <div
                  className={`p-3 rounded-xl max-w-xs inline-block  ${message?.messageType === 'incoming'
                      ? 'bg-gray-100 text-gray-800 self-end'
                      : 'bg-blue-100 text-gray-800'
                    }`}
                  style={
                    message?.messageType === 'outgoing'
                      ? { backgroundColor: '#E6F4FF' }
                      : {}
                  }
                >
                  {formVisible &&
                    (!infoUser?.contactId ||
                      !infoUser?.workspaceId) ? (
                    <Form
                      onFinish={handleFormSubmit}
                      layout="vertical"
                      form={form}
                      initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                      }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <p>{message.messageContent}</p>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your first name',
                              },
                            ]}
                            className="mb-0"
                          >
                            <Input
                              placeholder="Input first name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your last name',
                              },
                            ]}
                            className="mb-0"
                          >
                            <Input
                              placeholder="Input last name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your email',
                              },
                              { type: 'email', message: 'Invalid email' },
                            ]}
                            className="mb-0"
                          >
                            <Input
                              placeholder="Input email"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[
                              {
                                pattern: /^\+?[1-9]\d{1,14}$/,
                                message: 'Invalid phone number.',
                              },
                            ]}
                          >
                            <GTGSPhoneNumber
                              placeholder="Input phone number"
                              country={'vn'}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Col span={24}>
                        <Form.Item className="mb-2">
                          <Button type="primary" htmlType="submit" block disabled={isLoading}>
                            Send
                          </Button>
                        </Form.Item>
                      </Col>
                    </Form>
                  ) : (
                    <div
                      className={`rounded-lg p-0 ${message?.messageType === 'incoming' ? 'float-right' : 'float-left'}`}
                      style={{
                        width: 'fit-content',
                        wordBreak: 'break-word',
                        minHeight: '10px',
                        display: 'block',
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          message?.messageContent ||
                          message?.messageHtml ||
                          '<div/>',
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </Spin>
      </div>

      <div className="p-4 border-t border-gray-200">
        <form
          onSubmit={handleSendMessage}
          className="flex items-center space-x-2 bg-gray-100 p-2 rounded-xl"
        >
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            autoFocus
            placeholder="Type your message here..."
            className="flex-1 ml-3 bg-gray-100 border-none focus:outline-none text-sm placeholder-gray-500"
            aria-label="Chat message input"
            disabled={isInputDisabled && !infoUser?.contactId && !infoUser?.workspaceId}
          />
          <button
            type="submit"
            className={`flex items-center justify-center w-10 h-10 rounded-full ${isInputDisabled &&
                !infoUser?.contactId &&
                !infoUser?.workspaceId
                ? 'bg-gray-400'
                : 'bg-blue-600'
              }`}
            disabled={
              isInputDisabled &&
              !infoUser?.contactId &&
              !infoUser?.workspaceId
            }
          >
            <AiOutlineArrowRight className="text-white w-4 h-4" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;
