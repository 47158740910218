import httpService from './httpService';
import config from '../config';
import { customResponsePagination } from '@/helpers';
import qs from 'qs';

const baseUrl = `${config.gatewayUrl}/v1/workspaces`;
const baseUrlV2 = `${config.gatewayUrl}/v2/workspaces`;
const baseUrl_1 = `${config.gatewayUrl}/identity/v1`;

export const getWorkspaces = async (params) => {
  const { data } = await httpService.get(`${baseUrl}`, {
    params: params,
    paramsSerializer: (params) => {
      return params && qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return {
    data: {
      ...customResponsePagination({
        content: data.data?.workspaces || [],
        totalElementsCount: Number(data.data?.total || 0),
        currentPage: params?.page || 1,
        pageSize: params?.pageSize || 10,
      }),
      isTrial: data?.isTrial || false,
    },
    success: data.success,
  };
};

export const getWorkspacesBillingMembers = async (workspaceId, params) => {
  const { data } = await httpService.get(
    `${baseUrl}/${workspaceId}/billing-members`,
    {
      params: params,
      paramsSerializer: (params) => {
        return params && qs.stringify(params, { arrayFormat: 'repeat' });
      },
    }
  );

  return {
    data: {
      ...customResponsePagination({
        content: data?.billingMembers || [],
        totalElementsCount: Number(data?.totalRecords || 0),
        currentPage: params?.page || 1,
        pageSize: params?.pageSize || 10,
      }),
      availableSeats: data?.availableSeats || 0,
    },
    success: data.success,
  };
};

export const getWorkspacesV2 = async (params) => {
  const { data } = await httpService.get(`${baseUrlV2}`, {
    params: params,
    paramsSerializer: (params) => {
      return params && qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return {
    data: {
      ...customResponsePagination({
        content: data.data?.workspaces || [],
        totalElementsCount: Number(data.data?.total || 0),
        currentPage: params?.page || 1,
        pageSize: params?.pageSize || 10,
      }),
      numberNextInvoice: data.data?.numberNextInvoice || 0,
      totalInvoiceAmount: data.data?.totalInvoiceAmount || 0,
      totalMember: data.data?.totalMember || 0,
      remainingTrialDays: data?.remainingTrialDays || 0,
    },
    success: data.success,
  };
};

export const postWorkspaces = async (body) => {
  const { data } = await httpService.post(`${baseUrl}`, body);
  return data;
};

export const putWorkspaces = async (body) => {
  const { data } = await httpService.put(`${baseUrl}`, body);
  return data;
};

export const getPlanDetails = async (workspaceId) => {
  const { data } = await httpService.get(
    `${baseUrl}/${workspaceId}/plan-details`
  );
  return data;
};

export const getPasswordPolicies = async (body) => {
  const { data } = await httpService.get(`${baseUrl_1}/policies`, body);
  return data;
};

export const putWorkspacesProfile = async (workSpaceId, body) => {
  const { data } = await httpService.put(
    `${baseUrl}/${workSpaceId}/profile`,
    body
  );
  return data;
};

export const sendVerifyPhoneProfile = async (body) => {
  const { data } = await httpService.post(
    `${baseUrl_1}/profile/send-verify-phone`,
    body
  );
  return data;
};

export const postReactivateWorkspaces = async (workspaceId) => {
  const { data } = await httpService.post(
    `${baseUrl}/${workspaceId}/reactivate`
  );
  return data;
};

export const updatePasswordProfile = async (body) => {
  const { data } = await httpService.put(`${baseUrl_1}/profile/password`, body);
  return data;
};

export const confirmPhoneProfile = async (body) => {
  const { data } = await httpService.post(
    `${baseUrl_1}/profile/confirm-phone`,
    body
  );
  return data;
};

export const invitesWorkspaces = async (body) => {
  const { data } = await httpService.post(`${baseUrl}`, body);
  return data;
};

export const joinWorkspaces = async (workSpaceId, body) => {
  const { data } = await httpService.post(
    `${baseUrl}/${workSpaceId}/join`,
    body
  );
  return data;
};

export const rejectedWorkspaces = async (workSpaceId, body) => {
  const { data } = await httpService.post(
    `${baseUrl}/${workSpaceId}/rejected`,
    body
  );
  return data;
};

export const getModules = async () => {
  const { data } = await httpService.get(`${baseUrl_1}/modules`);
  return data;
};

export const getRoles = async () => {
  const { data } = await httpService.get(`${baseUrl_1}/roles`);
  return data;
};
export const getWorkspaceParticipants = async (workspaceId) => {
  const { data } = await httpService.get(
    `${baseUrl}/${workspaceId}/participants`
  );
  return data;
};

export const postWorkspaceParticipants = async ({
  workspaceId,
  participantsId,
  ...body
}) => {
  const { data } = await httpService.post(
    `${baseUrl}/${workspaceId}/participants/${participantsId}/action-status`,
    body
  );
  return data;
};

export const getWorkspaceRoles = async () => {
  const { data } = await httpService.get(`${baseUrl_1}/workspaces/roles`);
  return data;
};

export const getWorkspaceDetail = async (id: string) => {
  const { data } = await httpService.get(`${baseUrl}/${id}`);
  return data;
};

export const postOneTimePayment = async (body, workspaceId) => {
  const { data } = await httpService.post(
    `${baseUrl}/${workspaceId}/onetime-payment`,
    body
  );
  return data;
};

export const posFetchHostedPage = async (body, workspaceId) => {
  const { data } = await httpService.post(
    `${baseUrl}/${workspaceId}/fetch-hosted-page`,
    body
  );
  return data;
};

export const addWorkspaceRole = async (body) => {
  const { data } = await httpService.post(`${baseUrl_1}/roles`, body);
  return data;
};
export const deleteWorkspaceRole = async (roleId) => {
  const { data } = await httpService.delete(`${baseUrl_1}/roles/${roleId}`);
  return data;
};
export const updateWorkspaceRolePermissions = async ({
  roleId,
  body,
}: {
  roleId: string;
  body: { privileges: string[]; name: string; description: string };
}) => {
  const { data } = await httpService.put(`${baseUrl_1}/roles/${roleId}`, body);
  return data;
};

export const getWorkspaceRoleById = async (roleId) => {
  const { data } = await httpService.get(
    `${baseUrl_1}/workspaces/roles/${roleId}`
  );
  return data;
};
export const assignRoleToUser = async (payload: {
  roleId: string;
  authUserId: string;
}) => {
  const { data } = await httpService.post(`${baseUrl_1}/roles/assign`, payload);
  return data;
};
export const deleteWorkspaceById = async (workspaceId: string) => {
  const { data } = await httpService.delete(`${baseUrl}/${workspaceId}`);
  return data;
};
export const updateParticipantProfile = async ({
  workspaceId,
  participantId,
  body,
}: {
  workspaceId: string;
  participantId: string;
  body: {
    displayName: string;
    phone: string;
    position: string;
  };
}) => {
  const { data } = await httpService.put(
    `${baseUrl}/${workspaceId}/profile/${participantId}`,
    body
  );
  return data;
};
export const inviteUsersToWorkspace = async ({
  workspaceId,
  emailAddresses,
}: {
  workspaceId: string;
  emailAddresses: string[];
}) => {
  const body = {
    workspaceId,
    emailAddresses,
  };

  const { data } = await httpService.post(`${baseUrl}/invites`, body);
  return data;
};
const workspacesService = {
  getWorkspaces,
  postWorkspaces,
  putWorkspaces,
  invitesWorkspaces,
  joinWorkspaces,
  rejectedWorkspaces,
  putWorkspacesProfile,
  getModules,
  getRoles,
  sendVerifyPhoneProfile,
  confirmPhoneProfile,
  getPasswordPolicies,
  updatePasswordProfile,
  getWorkspaceParticipants,
  addWorkspaceRole,
  getWorkspaceDetail,
  deleteWorkspaceRole,
  updateWorkspaceRolePermissions,
  getWorkspaceRoleById,
  assignRoleToUser,
  getWorkspacesBillingMembers,
  updateParticipantProfile,
  getPlanDetails,
  postOneTimePayment,
  posFetchHostedPage,
  postReactivateWorkspaces,
  postWorkspaceParticipants,
  inviteUsersToWorkspace,
};

export default workspacesService;
